(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon/assets/javascripts/coupon-row.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon/assets/javascripts/coupon-row.js');
"use strict";

function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
const {
  useContext
} = React;
const {
  useSelector
} = ReactRedux;
const {
  ReactBadge: Badge
} = svs.ui;
const {
  selectSystemType
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  DescriptionSize
} = svs.components.lbUi.participants.constants;
const {
  CouponRowDescription,
  ReduceFreeBets
} = svs.components.tipsen.coupon;
const {
  Tipsinfo
} = svs.components.tipsen.tipsinfo;
const {
  CouponRowMatchAnalyses
} = svs.components.tipsen.couponRowMatchAnalyses;
const {
  CouponBetButtons
} = svs.components.tipsen.couponBetButtons;
const {
  useBetEvent
} = svs.components.sportinfo.matchInfoHooks;
const {
  useBranding,
  useIsTipsinfoEnabled
} = svs.components.tipsen.hooks;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  SYSTEM_REDUCERA_FRITT
} = svs.components.tipsen.engine.constants.systems;
const {
  selectDrawEvent,
  selectShowMinistatInfo,
  selectShowMatchAnalyseText,
  selectShowCompressedCoupon
} = svs.components.tipsen.selectors;
const {
  TipsenLink
} = svs.components.tipsen.tipsen_link;
const {
  getEventLink
} = svs.components.tipsen.tipsen_link.utils;
const {
  DrawContext
} = svs.components.tipsen.drawProvider;
const {
  useDrawSearchParams
} = svs.components.tipsen.drawProvider.hooks;
const {
  STATISTIC_SIDEBAR_QUERY
} = svs.components.tipsen.constants;
const {
  EventTypeField,
  EventTypeDistributionsFallback
} = svs.components.sportinfo.common.constants;
const {
  Ministat
} = svs.components.tipsen.ministatComponent;
const {
  RenderContext
} = svs.component.tipsen.renderContext;
const shouldDisableMiniStat = svs.core.detect.feature('ft-enetpulse-new');
const CouponRow = _ref => {
  var _productDistribution$;
  let {
    couponId,
    board,
    event: eventIndex,
    outcomeLabels,
    hasDisabledBets,
    isSelected
  } = _ref;
  const {
    pageType
  } = useContext(RenderContext);
  const {
    productId,
    drawNumber
  } = useContext(DrawContext);
  const drawId = "".concat(productId, "_").concat(drawNumber);
  const drawEvent = useSelector(state => selectDrawEvent(state, drawId, eventIndex));
  const systemType = useSelector(state => selectSystemType(state, couponId));
  const isReduceFree = systemType === SYSTEM_REDUCERA_FRITT;
  const branding = useBranding();
  const [productBranding] = branding;
  const {
    isAnyTipsinfoEnabled,
    isSvenskaFolketAsOutcomesEnabled
  } = useIsTipsinfoEnabled();
  const {
    betEvent,
    eventStatistics
  } = useBetEvent({
    productId,
    drawNumber,
    eventNumber: drawEvent.eventNumber
  });
  let productDistribution = [];
  if (eventStatistics) {
    var _eventStatistics$Even, _ref2, _eventStatisticsProdu;
    const eventStatisticsProductId = (_eventStatistics$Even = eventStatistics[EventTypeField.Distributions]) === null || _eventStatistics$Even === void 0 ? void 0 : _eventStatistics$Even[productId];
    productDistribution = (_ref2 = (_eventStatisticsProdu = eventStatisticsProductId === null || eventStatisticsProductId === void 0 ? void 0 : eventStatisticsProductId[drawNumber]) !== null && _eventStatisticsProdu !== void 0 ? _eventStatisticsProdu : eventStatisticsProductId === null || eventStatisticsProductId === void 0 ? void 0 : eventStatisticsProductId[EventTypeDistributionsFallback]) !== null && _ref2 !== void 0 ? _ref2 : [];
  }
  const hasDistribution = productDistribution && (((_productDistribution$ = productDistribution.current) === null || _productDistribution$ === void 0 ? void 0 : _productDistribution$.value) || []).filter(v => Boolean(v)).length > 0;
  const defaultOutcomes = outcomeLabels.map(el => el.text);
  let outcomes;
  if (hasDistribution && isSvenskaFolketAsOutcomesEnabled) {
    outcomes = defaultOutcomes.map((el, i) => productDistribution.current.value[i] ? "".concat(productDistribution.current.value[i], "%") : '-');
  } else {
    outcomes = defaultOutcomes;
  }
  const toParams = useDrawSearchParams(productId, drawNumber, drawEvent.eventNumber);
  const searchParams = new URLSearchParams(toParams);
  const showCouponAndDetailView = useMediaQuery(STATISTIC_SIDEBAR_QUERY);
  if (!showCouponAndDetailView) {
    searchParams.set('openStatistic', '');
  }
  const showMinistat = useSelector(selectShowMinistatInfo) && !shouldDisableMiniStat;
  const showMatchAnalysesInCoupon = useSelector(state => selectShowMatchAnalyseText(state, pageType));
  let showCompressedCoupon = useSelector(selectShowCompressedCoupon);
  showCompressedCoupon = useMediaQuery(breakpoints.down(BREAKPOINT_SM)) ? showCompressedCoupon : false;
  const classNames = ['coupon-row', "coupon-row-".concat(productBranding), ...(showCompressedCoupon ? ['coupon-row-compressed'] : []), ...(isReduceFree ? ['coupon-row-reduce-free'] : [])];
  const containerClassNames = ['coupon-row-container'];
  if (branding.length > 1) {
    containerClassNames.push(branding[branding.length - 1]);
  }
  const nameSize = DescriptionSize.Full;
  return React.createElement("li", {
    className: containerClassNames.join(' '),
    id: "event-".concat(betEvent.eventNumber)
  }, React.createElement(TipsenLink, _extends({
    className: classNames.join(' '),
    to: {
      pathname: getEventLink(),
      search: "?".concat(searchParams)
    }
  }, isSelected && showCouponAndDetailView ? {
    'aria-current': 'page'
  } : {}), !showCompressedCoupon && React.createElement(Badge, {
    branding: productBranding,
    className: "coupon-row-number f-500"
  }, drawEvent.eventNumber), React.createElement(CouponRowDescription, {
    eventNumber: showCompressedCoupon ? betEvent.eventNumber : null,
    eventTypeId: betEvent.eventTypeId,
    nameSize: nameSize,
    participants: betEvent.participants
  }), isReduceFree ? React.createElement("div", {
    className: "coupon-row-reduce-free-outcomes"
  }, React.createElement(ReduceFreeBets, {
    board: board,
    couponId: couponId,
    event: eventIndex,
    isDisabled: hasDisabledBets,
    outcomeLabels: outcomeLabels
  })) : React.createElement(CouponBetButtons, {
    board: board,
    couponId: couponId,
    event: eventIndex,
    hasDisabledBets: hasDisabledBets,
    outcomeLabels: outcomeLabels,
    outcomes: outcomes
  }), isAnyTipsinfoEnabled && React.createElement("div", {
    className: "pg_coupon-row_tipsinfo"
  }, React.createElement(Tipsinfo, {
    drawId: drawId,
    drawNumber: drawNumber,
    eventNumber: drawEvent.eventNumber,
    isReduceFree: isReduceFree,
    outcomeLabels: outcomeLabels,
    productId: productId
  })), showMatchAnalysesInCoupon && React.createElement(CouponRowMatchAnalyses, {
    drawNumber: drawNumber,
    eventNumber: drawEvent.eventNumber,
    matchId: betEvent.matchId,
    productId: productId
  }), betEvent.eventComment && React.createElement("div", {
    className: "tipsinfo-event-comment"
  }, betEvent.eventComment)), showMinistat && React.createElement(Ministat, {
    eventNumber: drawEvent.eventNumber,
    matchId: betEvent.matchId
  }));
};
setGlobal('svs.components.tipsen.coupon.CouponRow', CouponRow);

 })(window);